import React, { useState } from "react";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { Button } from "../Button/Button";
import { NotifyError, NotifySuccess } from "../../Utils/Helpers/Notify";
import { Erc20ABI } from "../../Utils/SmartContracts/TemplateContracts/Constant";

const EnableTrading = ({ contractAddress, setTradingActive }) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const ManagedContract = {
    address: contractAddress,
    abi: Erc20ABI,
  };

  // ============================ 📝 CONTRACT WRITES 📝 =============================
  const { config } = usePrepareContractWrite({
    ...ManagedContract,
    functionName: "openTrading",
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.reason);
    },
  });

  const {
    write: WriteFunction,
    data: functionWroteData,
    isSuccess: iswriteStarted,
  } = useContractWrite({
    ...config,
    onSuccess(data) {
      //
    },
    onError(data) {
      setErrorMsg(data.message);
      NotifyError(data.message);
      setBtnLoading(false);
    },
  });

  const { isSuccess: txSuccess } = useWaitForTransaction({
    hash: functionWroteData?.hash,
    onSuccess(data) {
      NotifySuccess("trading is live");
      setBtnLoading(false);
      setTradingActive(true);

      //   setRevealed(true);
    },
    onError(error) {
      NotifyError(error.message);
      setBtnLoading(false);
    },
  });

  const clickHandler = () => {
    if (isBtnLoading) return;

    if (prepareError) {
      NotifyError(errorMsg);
    } else {
      setBtnLoading(true);
      WriteFunction?.();
    }
  };

  return (
    <div className="w-44">
      <Button
        onClick={clickHandler}
        disabled={isBtnLoading}
        buttonType={prepareError || isBtnLoading ? "deployInactive" : "deploy"}
      >
        {isBtnLoading ? "Enabling..." : "Enable Trading"}
      </Button>
    </div>
  );
};

export default EnableTrading;
