import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

// const steps = [
//   {
//     name: "Txn Confirmation",
//     description: "Open in wallet.",
//     status: "complete",
//   },
//   {
//     name: "Deployment",
//     description: "Deploying smart contract.",
//     status: "current",
//   },
//   {
//     name: "Verification",
//     description: "Verifying source code.",
//     status: "upcoming",
//   },
//   {
//     name: "Done",
//     description: "Smart contract creation successful.",
//     status: "upcoming",
//   },
//   // {
//   //   name: "Preview",
//   //   description: "Iusto et officia maiores porro ad non quas.",
//   //   href: "#",
//   //   status: "upcoming",
//   // },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProcessingSteps({ currentProcessingStep }) {
  const [steps, setSteps] = useState([
    {
      id: 1,
      tag: "Step 1",
      name: "Txn Confirmation",
      description: "Open in wallet.",
      status: "",
    },
    {
      id: 2,
      tag: "Step 2",
      name: "Deployment",
      description: "Deploying smart contract.",
      status: "",
    },
    {
      id: 3,
      tag: "Step 3",
      name: "Verification",
      description: "Verifying source code.",
      status: "",
    },
    {
      id: 4,
      tag: "Step 4",
      name: "Done",
      description: "Smart contract creation successful.",
      status: "",
    },
  ]);

  const updateStatus = (indices, newStatusArray) => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];

      indices.forEach((index, i) => {
        if (newSteps[index] && newStatusArray[i]) {
          newSteps[index] = { ...newSteps[index], status: newStatusArray[i] };
        }
      });

      return newSteps;
    });
  };

  useEffect(() => {
    switch (true) {
      case currentProcessingStep === 1:
        updateStatus(
          [0, 1, 2, 3],
          ["current", "upcoming", "upcoming", "upcoming"]
        );
        break;
      case currentProcessingStep === 2:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "current", "upcoming", "upcoming"]
        );
        break;
      case currentProcessingStep === 3:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "complete", "current", "upcoming"]
        );
        break;
      case currentProcessingStep === 4:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "complete", "complete", "complete"]
        );
        break;
      default:
        // Render a default component or handle unexpected state values
        console.log("an error has occurred");
        break;
    }
  }, [currentProcessingStep]);

  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-accent"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-accent group-hover:bg-accent">
                      <CheckIcon
                        className="h-5 w-5 text-primary"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-white">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </div>
              </>
            ) : step.status === "current" ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className="group relative flex items-start"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-accent" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-accent">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
