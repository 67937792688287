import React from "react";
import ContractPageHead from "../../Components/ContractPageHead/ContractPageHead";
import contractTypeLogo from "../../Assets/erc20.svg";
import DeploymentHistory from "../../Components/DeploymentHistory/DeploymentHistory";

const ERC20 = () => {
  return (
    <div className="space-y-6">
      <ContractPageHead
        logo={contractTypeLogo}
        type={"ERC20"}
        description={
          "A standard for fungible tokens typically used for cryptocurrencies. Ideal for tokens with a fixed supply and identical value for each unit. For more information, check out our detailed documentation guide."
        }
      />
      <DeploymentHistory />
    </div>
  );
};

export default ERC20;
