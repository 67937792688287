import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import Navbar from "./Components/Navbar/Navbar";
import ERC20 from "./Routes/ERC20/ERC20";
import CreateNew from "./Routes/CreateNew/CreateNew";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageContract from "./Routes/Manage/ManageContract";

function App() {
  return (
    <div className="h-screen  font-DMsans">
      <div className="bg-primary fixed h-screen w-full" />
      <ScrollToTop />
      <Header />
      <Navbar />
      <div className="tab:pl-[110px] mon:pl-[300px] pt-20 relative">
        <div className="px-16 py-10">
          <Routes>
            <Route path="/" element={<ERC20 />} />
            <Route path="/create-new" element={<CreateNew />} />
            <Route
              path="/manage/:contractAddress"
              element={<ManageContract />}
            />
          </Routes>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default App;
