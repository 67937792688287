import React, { useEffect, useState } from "react";

import Logo3 from "../../Assets/erc20.svg";
import ManageContractPageHead from "../../Components/ManageContractPageHead/ManageContractPageHead";
import { useParams } from "react-router-dom";
// import ManageOverview from "../../Components/ManageOverview/ManageOverview";
// import ManageTabs from "../../Components/ManageTabs/ManageTabs";
import Erc721AManager from "../../Components/ContractManager/Erc721AManager";
import Erc20Manager from "../../Components/ContractManager/Erc20Manager";

const ManageContract = () => {
  let { contractAddress } = useParams();

  // console.log(type, contractAddress);

  return (
    <div>
      <div className="w-full rounded-md bg-elements boxShadow1 px-7 py-6 space-y-14">
        <ManageContractPageHead
          logo={Logo3}
          description={`Keep tabs on your ERC20 smart contract by enjoying a hassle-free and efficient smart contract management experience.`}
        />
        {/* <ManageOverview /> */}

        {/* <ManageTabs /> */}

        <Erc20Manager contractAddress={contractAddress} />
      </div>
    </div>
  );
};

export default ManageContract;
