import React, { useState, useEffect } from "react";
import { FormInput } from "../FormInput/FormInput";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import {
  blockInvalidChar,
  blockInvalidCharWithDecimal,
} from "../../Utils/Helpers/BlockInvalidChar";
import { generateRoot } from "../../Utils/Helpers/Merkle";
import { Button } from "../Button/Button";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { isValidAddress } from "../../Utils/Helpers/AddressValidator";

const Erc721AForm = ({ setActiveStep, setSpecsToDeploy }) => {
  const [whitelistInputValue, setWhitelistInputValue] = useState("");
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [errors, setErrors] = useState({
    nameError: false,
    nameErrorMsg: "",
    tickerError: false,
    tickerErrorMsg: "",
    supplyError: false,
    supplyErrorMsg: "",
    publicMintPriceError: false,
    publicMintPriceErrorMsg: "",
    maxPublicMintsError: false,
    maxPublicMintsErrorMsg: "",
    maxWlMintsError: false,
    maxWlMintsErrorMsg: "",
    wlMintPriceError: false,
    wlMintPriceErrorMsg: "",
    // maxFreeMintsError: false,
    // maxFreeMintsErrorMsg: "",
    ipfsError: false,
    ipfsErrorMsg: "",
    whitelistAddressesInvalidError: false,
    whitelistAddressesInvalidErrorMsg: "",
  });

  const [erc721AConfigs, setErc721AConfigs] = useState({
    name: "",
    symbol: "",
    supply: "",
    root: "",
    publicMintPrice: "",
    maxPublicMints: "",
    whitelistMintPrice: "",
    maxWhitelistMints: "",
    // maxFreeMints: "",
    ipfsLink: "",
    whitelist: false,
    // contractMintDisabled: false,
    // preReveal: false,
    // partialFreeMints: false,
  });

  // Toggle switches
  const toggleWhitelist = () => {
    setErc721AConfigs({
      ...erc721AConfigs,
      whitelist: !erc721AConfigs.whitelist,
    });
  };

  // const toggleAntiContractMints = () => {
  //   setErc721AConfigs({
  //     ...erc721AConfigs,
  //     contractMintDisabled: !erc721AConfigs.contractMintDisabled,
  //   });
  // };

  // const togglePreReveal = () => {
  //   setErc721AConfigs({
  //     ...erc721AConfigs,
  //     preReveal: !erc721AConfigs.preReveal,
  //   });
  // };

  // const togglePartialFreeMints = () => {
  //   setErc721AConfigs({
  //     ...erc721AConfigs,
  //     partialFreeMints: !erc721AConfigs.partialFreeMints,
  //   });
  // };

  // Form handlers
  const handleNameField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, name: newValue });

    if (newValue.length > 50) {
      setErrors({
        ...errors,
        nameError: true,
        nameErrorMsg: "Name should be less than 50 characters",
      });
    } else
      setErrors({
        ...errors,
        nameError: false,
        nameErrorMsg: "",
      });
  };

  const handleSymbolField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, symbol: newValue });

    if (newValue.length > 30) {
      setErrors({
        ...errors,
        tickerError: true,
        tickerErrorMsg: "Name should be less than 30 characters",
      });
    } else setErrors({ ...errors, tickerError: false, tickerErrorMsg: "" });
  };

  const handleSupplyField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, supply: newValue });

    if ((newValue.length > 0 && newValue < 1) || newValue > 100000) {
      setErrors({
        ...errors,
        supplyError: true,
        supplyErrorMsg: "Supply must be between 1 and 100 000",
      });
    } else setErrors({ ...errors, supplyError: false, supplyErrorMsg: "" });
  };

  const handlePublicMintPriceField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, publicMintPrice: newValue });

    if (newValue.length > 0 && newValue < 0) {
      setErrors({
        ...errors,
        publicMintPriceError: true,
        publicMintPriceErrorMsg: "Public mint price must be a positive number",
      });
    } else
      setErrors({
        ...errors,
        publicMintPriceError: false,
        publicMintPriceErrorMsg: "",
      });
  };

  const handleMaxPublicMintsField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, maxPublicMints: newValue });

    if (newValue.length > 0 && newValue < 0) {
      setErrors({
        ...errors,
        maxPublicMintsError: true,
        maxPublicMintsErrorMsg: "Max public mints must be a positive number",
      });
    } else
      setErrors({
        ...errors,
        maxPublicMintsError: false,
        maxPublicMintsErrorMsg: "",
      });
  };

  const handleWhitelistMintPriceField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, whitelistMintPrice: newValue });

    if (newValue.length > 0 && newValue < 0) {
      setErrors({
        ...errors,
        wlMintPriceError: true,
        wlMintPriceErrorMsg: "Whitelist mint price must be a positive number",
      });
    } else
      setErrors({
        ...errors,
        wlMintPriceError: false,
        wlMintPriceErrorMsg: "",
      });
  };

  const handleMaxWhitelistMintsField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, maxWhitelistMints: newValue });

    if (newValue.length > 0 && newValue < 0) {
      setErrors({
        ...errors,
        maxWlMintsError: true,
        maxWlMintsErrorMsg: "Max whitelist mints must be a positive number",
      });
    } else
      setErrors({ ...errors, maxWlMintsError: false, maxWlMintsErrorMsg: "" });
  };

  // const handleMaxFreeMintsField = (e) => {
  //   const newValue = e.target.value;
  //   setErc721AConfigs({ ...erc721AConfigs, maxFreeMints: newValue });
  // };

  const handleIpfsLinkField = (e) => {
    const newValue = e.target.value;
    setErc721AConfigs({ ...erc721AConfigs, ipfsLink: newValue });
  };

  // Text area whitelist handler
  const whitelistAddressHandler = (e) => {
    const wlInputValue = e.target.value;

    setWhitelistInputValue(e.target.value);

    // console.log(wlInputValue.length);

    if (wlInputValue.length > 0) {
      const dataArray = wlInputValue.split("\n");

      const verifiedWhitelistArray = [];

      dataArray.filter((item) => {
        const isValid = isValidAddress(item);

        // Abort processing if the address is invalid
        if (!isValid) {
          setErrors({
            ...errors,
            whitelistAddressesInvalidError: true,
            whitelistAddressesInvalidErrorMsg:
              "One or more invalid addresses found",
          });
        } else {
          setErrors({
            ...errors,
            whitelistAddressesInvalidError: false,
            whitelistAddressesInvalidErrorMsg: "",
          });

          verifiedWhitelistArray.push(item);
        }
      });
      const wlRoot = generateRoot(verifiedWhitelistArray);

      setErc721AConfigs({ ...erc721AConfigs, root: wlRoot });

      // console.log(root);
      // console.log(verifiedWhitelistArray);
    } else {
      setErrors({
        ...errors,
        whitelistAddressesInvalidError: false,
        whitelistAddressesInvalidErrorMsg: "",
      });
      setErc721AConfigs({ ...erc721AConfigs, root: "" });
    }
  };

  const goToReviewHandler = () => {
    setActiveStep(3);
    setSpecsToDeploy(erc721AConfigs);
  };

  useEffect(() => {
    if (
      !errors.nameError &&
      !errors.supplyError &&
      !errors.tickerError &&
      !errors.publicMintPriceError &&
      !errors.maxPublicMintsError &&
      !errors.maxWlMintsError &&
      !errors.wlMintPriceError &&
      !errors.maxFreeMintsError &&
      !errors.ipfsError &&
      !errors.whitelistAddressesInvalidError &&
      //
      erc721AConfigs.name.length > 0 &&
      erc721AConfigs.supply.length > 0 &&
      erc721AConfigs.symbol.length > 0 &&
      erc721AConfigs.publicMintPrice.length > 0 &&
      erc721AConfigs.maxPublicMints.length > 0 &&
      erc721AConfigs.ipfsLink.length > 0 &&
      (erc721AConfigs.root.length > 0 || !erc721AConfigs.whitelist) &&
      (erc721AConfigs.maxWhitelistMints.length > 0 ||
        !erc721AConfigs.whitelist) &&
      (erc721AConfigs.whitelistMintPrice.length > 0 ||
        !erc721AConfigs.whitelist)
      // (erc721AConfigs.maxFreeMints.length > 0 ||
      //   !erc721AConfigs.partialFreeMints)
    ) {
      setAllFieldsValid(true);
    } else setAllFieldsValid(false);
  }, [erc721AConfigs, errors]);

  return (
    <div className="mx-auto max-w-3xl space-y-8 text-gray-400 ">
      <ScrollToTop />
      {/* /////////////////////////  BASE MINT CONFIGS   ///////////////////////// */}
      <div className="flex flex-col  gap-1">
        <span className="text-accent text-3xl">Let's get you started</span>
        <span className=" font-thin text-lg">
          Set up the on-chain properties of your NFT and how it will appear when
          viewed in wallets.
        </span>
      </div>
      <FormInput
        title="Token name:"
        type="text"
        placeholder="Ex: Bored Ape Yacht Club"
        inputType={errors.nameError ? "mainErrored" : "main"}
        error={errors.nameError}
        errorMsg={errors.nameErrorMsg}
        onChange={handleNameField}
        value={erc721AConfigs.name}
      />

      <div className="flex justify-between gap-10">
        <FormInput
          title="Ticker symbol:"
          type="text"
          placeholder="Ex: BAYC"
          inputType={errors.tickerError ? "mainErrored" : "main"}
          error={errors.tickerError}
          errorMsg={errors.tickerErrorMsg}
          onChange={handleSymbolField}
          value={erc721AConfigs.symbol}
        />
        <FormInput
          title="Total supply:"
          type="number"
          placeholder="Ex: 10, 000"
          icon="NFTs"
          inputType={errors.supplyError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidChar}
          onWheel={(e) => e.target.blur()}
          error={errors.supplyError}
          errorMsg={errors.supplyErrorMsg}
          onChange={handleSupplyField}
          value={erc721AConfigs.supply}
        />
      </div>

      {/* /////////////////////////  PUBLIC MINT CONFIGS  ///////////////////////// */}
      <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">
          Set public mint configurations
        </span>
        <span className="font-thin text-lg">
          Set up your desired public phase mint configurations for a fully
          custom mint experience.
        </span>
      </div>

      <div className="flex justify-between gap-10">
        <FormInput
          title="Public mint price:"
          type="number"
          placeholder="Ex: 0.1"
          icon="ETH"
          inputType={errors.publicMintPriceError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidCharWithDecimal}
          onWheel={(e) => e.target.blur()}
          error={errors.publicMintPriceError}
          errorMsg={errors.publicMintPriceErrorMsg}
          onChange={handlePublicMintPriceField}
          value={erc721AConfigs.publicMintPrice}
        />
        <FormInput
          title="Max public mints:"
          type="number"
          placeholder="Ex: 2"
          icon="NFTs"
          inputType={errors.maxPublicMintsError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidChar}
          onWheel={(e) => e.target.blur()}
          error={errors.maxPublicMintsError}
          errorMsg={errors.maxPublicMintsErrorMsg}
          onChange={handleMaxPublicMintsField}
          value={erc721AConfigs.maxPublicMints}
        />
      </div>

      {/* /////////////////////////  WHITELIST MINT CONFIGS  ///////////////////////// */}
      <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">
          Set whitelist mint configurations
        </span>
        <span className="font-thin text-lg">
          Set up your desired whitelist phase mint configurations for a fully
          custom mint experience.
        </span>
      </div>

      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable whitelist mint phase</p>
        <button onClick={toggleWhitelist}>
          <ToggleSwitch status={erc721AConfigs.whitelist} />
        </button>
      </div>

      {erc721AConfigs.whitelist && (
        <div className="space-y-8">
          <div className="flex justify-between gap-10">
            <FormInput
              title="Whitelist mint price:"
              type="number"
              placeholder="Ex: 0.05"
              icon="ETH"
              inputType={errors.wlMintPriceError ? "mainErrored" : "main"}
              onKeyDown={blockInvalidCharWithDecimal}
              onWheel={(e) => e.target.blur()}
              error={errors.wlMintPriceError}
              errorMsg={errors.wlMintPriceErrorMsg}
              onChange={handleWhitelistMintPriceField}
              value={erc721AConfigs.whitelistMintPrice}
            />
            <FormInput
              title="Max whitelist mints:"
              type="number"
              placeholder="Ex: 2"
              icon="NFTs"
              inputType={errors.maxWlMintsError ? "mainErrored" : "main"}
              onKeyDown={blockInvalidChar}
              onWheel={(e) => e.target.blur()}
              error={errors.maxWlMintsError}
              errorMsg={errors.maxWlMintsErrorMsg}
              onChange={handleMaxWhitelistMintsField}
              value={erc721AConfigs.maxWhitelistMints}
            />
          </div>

          <div className="space-y-2 w-full">
            <div className="flex flex-col gap-1  py-4">
              <span className="text-lg">Enter addresses to whitelist:</span>
              <span className="font-thin">
                Note that these addresses can be updated at any time through the
                manage smart contract section.
              </span>
            </div>
            <textarea
              className={`outline-none bg-primary rounded-md h-[500px] w-full resize-none px-3 py-3 placeholder-gray-500 placeholder-opacity-50 border pb-1 ${
                errors.whitelistAddressesInvalidError
                  ? "border-red-800"
                  : "border-gray-700"
              }`}
              type=""
              required
              placeholder="Whitelist addresses, one per line ex:                          
            0xB8c77482e45F1F44dE1745F52C74426C631bDD52
            0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
            0xdac17f958d2ee523a2206206994597c13d831ec7"
              spellCheck="false"
              onChange={whitelistAddressHandler}
              value={whitelistInputValue}
            />
            {errors.whitelistAddressesInvalidError && (
              <span className="text-red-800 ml-1">
                {errors.whitelistAddressesInvalidErrorMsg}
              </span>
            )}
          </div>
        </div>
      )}

      {/* /////////////////////////  ADD-ON MINT CONFIGS  ///////////////////////// */}
      {/* <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">Some cool add-ons</span>
        <span className="font-thin text-lg">
          Additional features to provide an enhanced personal experience.
        </span>
      </div>
      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable contract minting protection</p>
        <button onClick={toggleAntiContractMints}>
          <ToggleSwitch status={erc721AConfigs.contractMintDisabled} />
        </button>
      </div>
      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable pre-reveal art launch state</p>
        <button onClick={togglePreReveal}>
          <ToggleSwitch status={erc721AConfigs.preReveal} />
        </button>
      </div>

      <div>
        <div className=" text-2xl  flex justify-between items-center pb-4">
          <p>Enable partial FREE mints</p>
          <button onClick={togglePartialFreeMints}>
            <ToggleSwitch status={erc721AConfigs.partialFreeMints} />
          </button>
        </div>

        {erc721AConfigs.partialFreeMints && (
          <div className="">
            <span className="font-thin">
              Enter the number of FREE mints allowed per wallet (must be lower
              than max mints)
            </span>
            <FormInput
              type="number"
              placeholder="Ex: 1"
              icon="FREE MINTS"
              inputType={errors.maxFreeMintsError ? "mainErrored" : "main"}
              onKeyDown={blockInvalidChar}
              onWheel={(e) => e.target.blur()}
              error={errors.maxFreeMintsError}
              errorMsg={errors.maxFreeMintsErrorMsg}
              onChange={handleMaxFreeMintsField}
              value={erc721AConfigs.maxFreeMints}
            />
          </div>
        )}
      </div> */}

      {/* /////////////////////////  IPFS ART CONFIGS  ///////////////////////// */}
      <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">One final Touch of Art</span>
        <span className="font-thin text-lg">
          Add your IPFS link in order to pair your stunning creations with the
          blockchain world.
        </span>
        {erc721AConfigs.preReveal && (
          <p className="font-thin text-lg">
            For pre-reveal launch state your ipfs link should resemble this
            format:{" "}
            <span className="underline underline-offset-4">
              ipfs://CID/unrevealed.json
            </span>
          </p>
        )}
      </div>
      <FormInput
        title="IPFS link:"
        type="text"
        placeholder="Ex: ipfs://afkriansq7hys2lo0bt9a1ds78c2cbvgbh4fq2usha3xdsqalrhl72imgbdm"
        inputType={errors.ipfsError ? "mainErrored" : "main"}
        error={errors.ipfsError}
        errorMsg={errors.ipfsErrorMsg}
        onChange={handleIpfsLinkField}
        value={erc721AConfigs.ipfsLink}
      />

      {/* /////////////////////////  NEXT STEP BTN  ///////////////////////// */}
      <div className="flex justify-between pt-10">
        <Button onClick={() => setActiveStep(1)} buttonType="mainSm">
          Back
        </Button>
        <Button
          disabled={!allFieldsValid}
          onClick={goToReviewHandler}
          buttonType={allFieldsValid ? "mainSm" : "mainSmInactive"}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Erc721AForm;

/*
input

peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-3 rounded-md border-blue-gray-200 focus:border-gray-900

label

flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900
*/
