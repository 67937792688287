import React from "react";

const ToggleSwitch = ({ status }) => {
  return (
    <button className="pointer-events-none">
      <label class="switch">
        <input type="checkbox" checked={status} />
        <span class="slider"></span>
      </label>
    </button>
  );
};

export default ToggleSwitch;
