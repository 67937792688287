import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import { ReactComponent as Eth } from "../../Assets/Eth.svg";
import { Button } from "../Button/Button";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const tiers = [
  // {
  //   name: "Basic",
  //   id: "tier-basic",
  //   href: "#",
  //   tierPrice: "0",
  //   description: "Quis suspendisse ut fermentum neque vivamus non tellus.",
  //   mostPopular: false,
  //   tierCode: 1,
  // },
  {
    name: "Lifetime",
    id: "tier-lifetime",
    href: "#",
    tierPrice: "0.5",
    description: "Quis eleifend a tincidunt pellentesque. A tempor in sed.",
    mostPopular: true,
    tierCode: 3,
  },
  // {
  //   name: "Premium",
  //   id: "tier-premium",
  //   href: "#",
  //   tierPrice: "0.2",
  //   description: "Orci volutpat ut sed sed neque, dui eget. Quis tristique.",
  //   mostPopular: false,
  //   tierCode: 2,
  // },
];
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "All token standards",
        tiers: { Basic: true, Lifetime: true, Premium: true },
      },
      {
        name: "Advanced features",
        tiers: { Basic: false, Lifetime: true, Premium: true },
      },
      {
        name: "Platform fees",
        tiers: { Basic: "15%", Lifetime: "0%", Premium: "5%" },
      },
      {
        name: "Team members",
        tiers: { Lifetime: "Up to 5 users" },
      },
    ],
  },
  // {
  //   name: "Reporting",
  //   features: [
  //     {
  //       name: "Advanced analytics",
  //       tiers: { Basic: true, Lifetime: true, Premium: true },
  //     },
  //     { name: "Basic reports", tiers: { Lifetime: true, Premium: true } },
  //     { name: "Professional reports", tiers: { Premium: true } },
  //     { name: "Custom report builder", tiers: { Premium: true } },
  //   ],
  // },
  {
    name: "Support",
    features: [
      {
        name: "24/7 online support",
        tiers: { Basic: true, Lifetime: true, Premium: true },
      },
      {
        name: "Quarterly product workshops",
        tiers: { Lifetime: true, Premium: true },
      },
      {
        name: "Priority phone support",
        tiers: { Lifetime: true, Premium: true },
      },
      { name: "1:1 onboarding tour", tiers: { Premium: true } },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingPlans({ setActiveStep }) {
  const selectPlanHandler = () => {
    setActiveStep(2);
  };
  return (
    <div>
      <ScrollToTop />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-accent">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-400 sm:text-5xl">
            Plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 font-thin text-gray-400">
          Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
          quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
        </p> */}

        {/* xs to lg */}
        <div className="w-full flex justify-center mx-auto  max-w-md space-y-8  lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className="rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200 p-8"
            >
              <h3
                id={tier.id}
                className="text-sm font-semibold leading-6 text-gray-400"
              >
                yoyo
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-400">
                <Eth className="h-8 w-8 mt-1" />
                <span className="text-4xl font-bold">0.1</span>
                <span className="text-sm font-semibold">
                  {tier.id === "tier-basic"
                    ? "/FREE"
                    : tier.id === "tier-lifetime"
                    ? "/Lifetime"
                    : "/Per use"}
                </span>
              </p>
              {/* <a
                href={tier.href}
                aria-describedby={tier.id}
                className={
                  tier.mostPopular
                    ? "bg-accent text-white hover:bg-opacity-80 transition-all duration-300 ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    : "transition-all duration-300 text-accent ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent hover:bg-opacity-20  hover:shadow-[0_0_10px_rgba(194,49,116,1)]"
                }
              >
                Select plan
              </a> */}
              <Button
                onClick={selectPlanHandler}
                buttonType={
                  tier.mostPopular ? "pricingSolid" : "pricingOutlined"
                }
              >
                Select plan
              </Button>
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-400"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon
                              className="h-6 w-5 flex-none text-accent"
                              aria-hidden="true"
                            />
                            <span>
                              {feature.name}{" "}
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <span className="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* ================================ lg+ ================================ */}
        <div className="isolate  hidden lg:block">
          <div className="relative -mx-8">
            <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
              <div
                className="flex w-1/4 px-4"
                aria-hidden="true"
                style={{
                  marginLeft: `${
                    (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                  }%`,
                }}
              >
                <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
              </div>
            </div>

            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />

                  <th scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                    <div className="text-sm font-semibold leading-7 text-gray-400">
                      Pay Per Use
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>

                  <td className="px-6 pt-2 xl:px-8">
                    <div className="flex items-baseline gap-x-1 text-gray-400">
                      <Eth className="h-8 w-8 mt-1" />
                      <span className="text-4xl font-bold">0.1</span>
                      <span className="text-sm font-semibold leading-6">
                        /Per use
                      </span>
                    </div>

                    <Button
                      onClick={selectPlanHandler}
                      buttonType="pricingSolid"
                    >
                      Select plan
                    </Button>
                    {/* <a
                        href={tier.href}
                        className={
                          tier.mostPopular
                            ? "bg-accent text-white hover:bg-opacity-80 transition-all duration-300 ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            : "transition-all duration-300 text-accent ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent hover:bg-opacity-20  hover:shadow-[0_0_10px_rgba(194,49,116,1)]"
                        }
                      >
                        Select plan
                      </a> */}
                  </td>
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? "pt-8" : "pt-16",
                          "pb-4 text-sm font-semibold leading-6 text-accent"
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 text-sm font-normal leading-6 text-gray-400"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div className="text-center text-sm leading-6 text-gray-400">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className="mx-auto h-5 w-5 text-accent"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
