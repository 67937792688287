import { ethers } from "ethers";
import {
  DeployerContract,
  DeployerContractBlast,
} from "../SmartContracts/DeployerContract/Constant";

export const fetchUserDeploys = async (queryAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    DeployerContract.address,
    DeployerContract.abi,
    signer
  );

  const filter = contract.filters.Erc20Deployed();

  //   const filter = contract.filters.Erc721ADeployed();

  try {
    const events = await contract.queryFilter(filter);

    // Filter events for the target address
    const filteredEvents = events.filter(
      (event) => event.args.owner === queryAddress
    );

    return filteredEvents;
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
};

export const fetchUserDeploysBlast = async (queryAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    DeployerContractBlast.address,
    DeployerContractBlast.abi,
    signer
  );

  const filter = contract.filters.Erc20Deployed();

  //2304010 errored
  const fromBlock = 2307305;
  const toBlock = "latest";
  //   const filter = contract.filters.Erc721ADeployed();

  try {
    const events = await contract.queryFilter(filter, fromBlock, toBlock);

    // Filter events for the target address
    const filteredEvents = events.filter(
      (event) => event.args.owner === queryAddress
    );

    // console.log(filteredEvents);

    return filteredEvents;
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
};
