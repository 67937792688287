import React, { useState } from "react";
import { Button } from "../Button/Button";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { calculateTimeElapsed } from "../../Utils/Helpers/TimeElapsed";
import { useNavigate, useLocation } from "react-router-dom";
import { useNetwork } from "wagmi";

const BlastHistoryCard = ({ data }) => {
  const [isOpen, setOpen] = useState(false);

  const { chain } = useNetwork();

  const navigate = useNavigate();

  const { contractName, contractSymbol, timestamp, contractAddress } = data;

  // console.log(Number(timestamp));

  const openHandler = () => setOpen(!isOpen);

  const manageHandler = () => navigate(`/manage/${contractAddress}`);

  return (
    <div
      key={contractName}
      className="font-light flex items-center justify-between pb-8 "
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <p className="font-medium text-lg text-accent">
            {contractName} / {contractSymbol}
          </p>
        </div>
        <p>
          Created {calculateTimeElapsed(Number(timestamp))} · Using Pay Per Use
          plan
        </p>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-44">
          <Button onClick={manageHandler} buttonType="manage">
            Manage contract
          </Button>
        </div>

        <div className="relative">
          <button onClick={openHandler}>
            <EllipsisVerticalIcon class="h-8 w-8 text-gray-400" />
          </button>
          {isOpen && (
            <a
              href={
                // chain?.id === 5
                //   ? `https://goerli.etherscan.io/address/${contractAddress}`
                //   : `https://testnet.blastscan.io/address/${contractAddress}`

                chain?.id === 1
                  ? `https://etherscan.io/address/${contractAddress}`
                  : `https://blastscan.io/address/${contractAddress}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="absolute bottom-[-60px] right-2 w-32 h-12 bg-gray-400 boxShadow1 rounded-lg text-black font-bold flex justify-center items-center hover:bg-gray-500 cursor-pointer"
              onClick={() => setOpen(false)}
            >
              View explorer
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlastHistoryCard;
