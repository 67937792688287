import { useState } from "react";

import {
  // PresentationChartBarIcon,
  // DocumentPlusIcon,
  // BuildingLibraryIcon,
  // GlobeAltIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";

import { DocumentTextIcon } from "@heroicons/react/24/outline";

import { useAccount, useDisconnect } from "wagmi";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as ContractIcon } from "../../Assets/SmartContractIcon.svg";
import { ReactComponent as Arrows } from "../../Assets/arrows.svg";
// import { ReactComponent as Erc721A } from "../../Assets/NavbarLogos/721a.svg";
// import { ReactComponent as Erc1155 } from "../../Assets/NavbarLogos/erc1155.svg";
import { ReactComponent as Erc20 } from "../../Assets/NavbarLogos/erc20.svg";
// import { ReactComponent as SBT } from "../../Assets/NavbarLogos/SBT.svg";

const Navbar = () => {
  // const [activeTab, setActiveTab] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  //   const [open, setOpen] = useState(0);
  //   const [openAlert, setOpenAlert] = useState(true);
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const location = useLocation();

  //   const handleOpen = (value) => {
  //     setOpen(open === value ? 0 : value);
  //   };

  const handleDisconnect = () => {
    disconnect();
  };

  const drawerHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className=" tab:block hidden">
      <div
        className={`w-full z-40 mt-10 pl-4 py-4 h-screen fixed flex rounded-r-3xl flex-col justify-between bg-elements 
        ${drawerOpen ? "max-w-[300px]" : "max-w-[110px]"}
        mon:max-w-[300px]
        `}
      >
        <div>
          <div className=" items-center mt-12 pl-3 gap-2 text-gray-400 font-light hidden mon:flex">
            <ContractIcon className="h-3.5 w-3.5" />
            <p>Smart Contracts</p>
          </div>

          <div className="text-[#F5E9F7] mt-12 space-y-2 mon:mt-2 flex flex-col gap-1  py-2  text-base font-normal cursor-pointer">
            <Link to="/">
              <div className="relative flex">
                <div
                  className={`navListItemActive ${
                    drawerOpen ? "w-full" : "w-[60px] mon:w-full"
                  }`}
                >
                  <div className={drawerOpen ? "mx-0" : "mx-auto mon:mx-0"}>
                    <Erc20
                      className={
                        drawerOpen ? "h-6 w-6" : "h-9 w-9 mon:h-6 mon:w-6"
                      }
                    />
                  </div>
                  <p
                    className={`${
                      drawerOpen ? "block pl-4" : "hidden"
                    } mon:block mon:pl-4`}
                  >
                    ERC20
                  </p>
                </div>

                <div className="absolute right-0 top-1/2 -translate-y-1/2 w-2 h-9 rounded-l-2xl bg-accent  opacity-100 transition-all duration-500" />
              </div>
            </Link>
          </div>

          {/* ==================================================================== */}

          <div className="h-[0.25px] w-full bg-[#F5E9F7] my-4"></div>
          <div className="text-[#F5E9F7] space-y-2 mt-2 flex flex-col gap-1  p-2  text-base font-normal ">
            <a
              href="https://docs.pulseai.bot/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className={`navListItemInactive ${
                  drawerOpen ? "w-full" : "w-[60px] mon:w-full"
                }`}
              >
                <div className={drawerOpen ? "mx-0" : "mx-auto mon:mx-0"}>
                  <DocumentTextIcon
                    className={
                      drawerOpen ? "h-5 w-5" : "h-7 w-7 mon:h-5 mon:w-5"
                    }
                  />
                </div>
                <p
                  className={`${
                    drawerOpen ? "block pl-4" : "hidden"
                  } mon:block mon:pl-4`}
                >
                  Docs
                </p>
              </div>
            </a>

            {/*  */}

            <div
              onClick={handleDisconnect}
              className={`navListItemInactive ${
                drawerOpen ? "w-full" : "w-[60px] mon:w-full"
              }`}
            >
              <div className={drawerOpen ? "mx-0" : "mx-auto mon:mx-0"}>
                <PowerIcon
                  className={drawerOpen ? "h-5 w-5" : "h-7 w-7 mon:h-5 mon:w-5"}
                />
              </div>
              <p
                className={`${
                  drawerOpen ? "block pl-4" : "hidden"
                } mon:block mon:pl-4`}
              >
                Disconnect
              </p>
            </div>
          </div>
        </div>
        {/* ALERT */}
        {/* <Alert
        open={openAlert}
        className="fixed bottom-2 left-2 w-[19rem]"
        onClose={() => setOpenAlert(false)}
      >
        <XLogo className="mb-4 h-7 w-7" />
        <Typography variant="h6" className="mb-1 py-1 text-[20px]">
          Join The Buzz 🔥
        </Typography>
        <Typography variant="small" className="font-ExoLight opacity-80 ">
          Tweet about EzTokn and be part of our groundbreaking journey. We're on
          the lookout for tweets from visionaries like you! Fuel our mission,
          and let's redefine web 3 together.
        </Typography>
        <div className="mt-4 flex gap-3">
          <Typography
            variant="small"
            className="font-medium opacity-80 cursor-pointer"
            onClick={() => setOpenAlert(false)}
          >
            Dismiss
          </Typography>

          <Typography
            as="a"
            href="https://twitter.com/intent/tweet?text=Just discovered @Ez_Tokn - the future of effortless token creation and management! 💼 Navigating the world of Web3 has never been simpler. I'm excited to be part of this groundbreaking journey! 🚀"
            target="_blank"
            rel="noopener noreferrer"
            variant="small"
            className="font-medium"
          >
            Tweet Now
          </Typography>
        </div>
      </Alert> */}
      </div>
      <div
        onClick={drawerHandler}
        className={`mon:hidden h-screen mt-[80px] absolute z-30 bg-gray-300 group bg-opacity-0 hover:bg-opacity-5  ${
          drawerOpen ? "ml-[300px]" : "ml-[90px]"
        }
      w-[90px] hover:w-[80px] rounded-r-3xl transition-all duration-300 cursor-pointer flex justify-end`}
      >
        <div className="pt-8">
          <Arrows
            className={`w-7 h-7 opacity-60 transition-all duration-200 group-hover:opacity-100 ${
              !drawerOpen && "rotate-180"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
