import React, { useState } from "react";
import ProcessingSteps from "../Steps/ProcessingSteps";
import Erc20ReviewCard from "../ContractReviewCards/Erc20ReviewCard";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useNetwork } from "wagmi";

const ReviewAndDeploy = ({ specsToDeploy, planTier, setActiveStep }) => {
  const [currentProcessingStep, setCurrentProcessingStep] = useState(1);
  const [deployedContract, setDeployedContract] = useState({
    contractAddress: "",
    hash: "",
    symbol: "",
  });

  const { chain } = useNetwork();

  const navigate = useNavigate();

  const goToMyContractsHandler = () => {
    navigate(`/`);
  };

  return (
    <div className="flex  justify-center pb-10">
      <div className="bg-[#171717] p-10 rounded-lg">
        <div className="text-white flex  justify-center font-normal text-4xl pb-14">
          REVIEW & DEPLOY
        </div>
        <div className=" flex items-center gap-24 ">
          <ProcessingSteps currentProcessingStep={currentProcessingStep} />
          <div className="text-gray-400 text-xl  ">
            <Erc20ReviewCard
              setCurrentProcessingStep={setCurrentProcessingStep}
              specsToDeploy={specsToDeploy}
              setActiveStep={setActiveStep}
              setDeployedContract={setDeployedContract}
            />
          </div>
        </div>

        {deployedContract.contractAddress && (
          <div className="text-gray-400 mt-20 text-center">
            <div className="text-green-600  text-3xl">Deployment success</div>
            <div className="mt-4">
              Contract address: {deployedContract.contractAddress}
            </div>
            {/* <div className="mt-4 flex gap-2 justify-center">
              Contract address:
              <div className="relative">
                {deployedContract.contractAddress}
                <div className="absolute bg-gray-100 bg-opacity-5 backdrop-blur-[4px] rounded-md w-full h-[25px] top-0" />
              </div>
            </div> */}
            <div className="flex items-center gap-10 justify-center">
              <a
                href={
                  // chain?.id === 5
                  //   ? `https://goerli.etherscan.io/tx/${deployedContract.hash}`
                  //   : `https://testnet.blastscan.io/tx/${deployedContract.hash}`

                  chain?.id === 1
                    ? `https://etherscan.io/tx/${deployedContract.hash}`
                    : `https://blastscan.io/tx/${deployedContract.hash}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="w-44"
              >
                <Button buttonType="pricingOutlined">View in explorer</Button>
              </a>

              <div className="w-44">
                <Button
                  onClick={goToMyContractsHandler}
                  buttonType="pricingOutlined"
                >
                  My contracts
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewAndDeploy;
