import React from "react";

const ContractPageHead = ({ logo, type, description }) => {
  return (
    <div className="w-full rounded-md bg-elements boxShadow1 px-7  py-6 flex flex-col justify-center space-y-4 relative">
      <div className="flex items-center gap-4">
        <img src={logo} alt="smart-contract-type" className="h-12 w-12" />
        <span className="text-accent font-medium text-4xl">{type}</span>
      </div>
      <p className="text-xl font-thin text-gray-400 textShadow">
        {description}
      </p>

      <img
        src={logo}
        alt="smart-contract-type"
        className="absolute top-1/2 -translate-y-1/2 right-6 opacity-20 h-32 w-32"
      />
    </div>
  );
};

export default ContractPageHead;
