import React from "react";
import { useParams } from "react-router-dom";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { addCommasToIntegerString } from "../../Utils/Helpers/AddCommasToNumbers";
import { formatEther } from "ethers/lib/utils.js";

const ManageOverview = ({ specs, limitsActive, tradingActive }) => {
  const {
    name,
    symbol,
    supply,
    maxWallet,
    maxTransaction,
    buyTax,
    sellTax,
    isTaxToken,
  } = specs;

  // console.log(Number(maxWallet) < 100);
  // console.log(addCommasToIntegerString(Number(supply).toString()));
  // console.log(formatEther(publicPrice));
  // console.log(isWhitelistNft);
  // console.log(Number(maxWallet));

  return (
    <div className=" bg-[#171717] rounded-lg text-gray-400 text-xl py-6 px-10 flex flex-wrap justify-center  gap-14">
      <div>
        <p className="font-thin pb-2">Name</p>
        <p className="text-3xl">{name}</p>
      </div>

      <div>
        <p className="font-thin pb-2">Symbol</p>
        <p className="text-3xl">{symbol}</p>
      </div>

      <div>
        <p className="font-thin pb-2">Supply</p>
        <p className="text-3xl">
          {addCommasToIntegerString(Number(supply).toString())}
        </p>
      </div>

      <div>
        <p className="font-thin pb-2">Balance Protection</p>
        {Number(maxWallet) < 100 ? (
          <div>
            <CheckIcon class="h-8 w-8 text-green-500" />
          </div>
        ) : (
          <XMarkIcon class="h-8 w-8 text-red-500" />
        )}
      </div>

      {Number(maxWallet) < 100 && (
        <div>
          <p className="font-thin pb-2">Max Balance</p>
          <p className="text-3xl">{Number(maxWallet)} %</p>
        </div>
      )}

      <div>
        <p className="font-thin pb-2">Txn Protection</p>
        {Number(maxTransaction) < 100 ? (
          <div>
            <CheckIcon class="h-8 w-8 text-green-500" />
          </div>
        ) : (
          <XMarkIcon class="h-8 w-8 text-red-500" />
        )}
      </div>

      {Number(maxTransaction) < 100 && (
        <div>
          <p className="font-thin pb-2">Max Transaction</p>
          <p className="text-3xl">{Number(maxTransaction)} %</p>
        </div>
      )}

      {isTaxToken && (
        <div>
          <p className="font-thin pb-2">Buy Tax</p>
          <p className="text-3xl">{Number(buyTax)} %</p>
        </div>
      )}

      {isTaxToken && (
        <div>
          <p className="font-thin pb-2">Sell Tax</p>
          <p className="text-3xl">{Number(sellTax)} %</p>
        </div>
      )}

      <div>
        <p className="font-thin pb-2">Token Type</p>
        <p className="text-3xl">{isTaxToken ? "Tax Token" : "No Tax Token"}</p>
      </div>

      <div>
        <p className="font-thin pb-2">Trading Status</p>
        {tradingActive ? (
          <div className="text-green-500 text-3xl">ACTIVE</div>
        ) : (
          <div className="text-red-500 text-3xl">INACTIVE</div>
        )}
      </div>

      <div>
        <p className="font-thin pb-2">Limits</p>
        {limitsActive ? (
          <div className="text-green-500 text-3xl">ACTIVE</div>
        ) : (
          <div className="text-red-500 text-3xl">INACTIVE</div>
        )}
      </div>
    </div>
  );
};

export default ManageOverview;
