import React, { useState } from "react";
import { addCommasToIntegerString } from "../../Utils/Helpers/AddCommasToNumbers";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { shortenIpfs } from "../../Utils/Helpers/ShortenAddress";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import {
  DeployerContract,
  DeployerContractBlast,
} from "../../Utils/SmartContracts/DeployerContract/Constant";
import { ethers } from "ethers";
import { Button } from "../Button/Button";
import { NotifyError, NotifySuccess } from "../../Utils/Helpers/Notify";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const Erc20ReviewCard = ({
  specsToDeploy,
  setCurrentProcessingStep,
  setActiveStep,
  setDeployedContract,
}) => {
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [deploySuccess, setDeploySuccess] = useState(false);

  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { chain } = useNetwork();

  const {
    name,
    symbol,
    supply,
    maxBalance,
    maxTxn,
    buyTax,
    sellTax,
    taxes,
    maxBalanceProtection,
    maxTxnProtection,
  } = specsToDeploy;

  // console.log(specsToDeploy);

  // ============================ 📝 CONTRACT WRITES 📝 =============================
  const { config } = usePrepareContractWrite({
    // ...DeployerContract,

    address:
      // chain?.id === 5
      chain?.id === 1
        ? DeployerContract.address
        : DeployerContractBlast.address,
    abi: DeployerContract.abi,
    functionName: "deployContract",
    args: [
      name,
      symbol,
      supply,
      maxBalanceProtection ? maxBalance : "100",
      maxTxnProtection ? maxTxn : "100",
      taxes ? buyTax : "0",
      taxes ? sellTax : "0",
      taxes ? true : false,
    ],
    overrides: { value: ethers.utils.parseEther("0.1") },
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.reason);
    },
  });

  const {
    write: CreateContract,
    data: tokenCreationData,
    isSuccess: isTokenCreationStarted,
  } = useContractWrite({
    ...config,
    onSuccess(data) {
      setCurrentProcessingStep(2);
    },
    onError(data) {
      setErrorMsg(data.message);
      NotifyError(data.message);
      setBtnLoading(false);
    },
  });

  const { isSuccess: txSuccess } = useWaitForTransaction({
    hash: tokenCreationData?.hash,
    onSuccess(data) {
      setCurrentProcessingStep(3);
      setTimeout(() => {
        setCurrentProcessingStep(4);

        setActiveStep(4);

        NotifySuccess(`${symbol} successfully deployed`);
        setDeployedContract({
          contractAddress: data.logs[0].address,
          hash: tokenCreationData?.hash,
          symbol: symbol,
        });
        setBtnLoading(false); // check to remove and replace with another alternative
        setDeploySuccess(true);
      }, "2000");

      // console.log("data:", data.logs[0].address); // deployed addy
      // console.log("hash:", contractCreationData?.hash);
    },
    onError(error) {
      NotifyError(error.message);
      setBtnLoading(false);
    },
  });

  const deployClickHandler = () => {
    if (isBtnLoading) return;

    if (!isConnected) {
      openConnectModal();
    } else if (prepareError) {
      NotifyError(errorMsg);
    } else {
      setBtnLoading(true);
      CreateContract?.();
    }
  };

  return (
    <div className="w-[400px] ">
      <div className="space-y-4">
        <div className="flex justify-between">
          <span>Name:</span>
          <span className="font-thin">{specsToDeploy.name}</span>
        </div>
        <div className="flex justify-between">
          <span>Symbol:</span>
          <span className="font-thin">{specsToDeploy.symbol}</span>
        </div>
        <div className="flex justify-between">
          <span>Supply:</span>
          <span className="font-thin">
            {addCommasToIntegerString(specsToDeploy.supply)}
          </span>
        </div>

        <div className="flex justify-between">
          <span>Taxes:</span>
          <span className="font-thin">
            {specsToDeploy.taxes ? (
              <CheckIcon class="h-6 w-6 text-green-500" />
            ) : (
              <XMarkIcon class="h-6 w-6 text-red-500" />
            )}
          </span>
        </div>

        {/* IF TAXES IS TRUE SHOW TAX SPECS */}
        {specsToDeploy.taxes && (
          <div className="space-y-4">
            <div className="flex justify-between">
              <span>Buy Tax:</span>
              <span className="font-thin">{specsToDeploy.buyTax} %</span>
            </div>
            <div className="flex justify-between">
              <span>Sell Tax:</span>
              <span className="font-thin">{specsToDeploy.sellTax} %</span>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <span>Max Balance Protection:</span>
          <span className="font-thin">
            {specsToDeploy.maxBalanceProtection ? (
              <CheckIcon class="h-6 w-6 text-green-500" />
            ) : (
              <XMarkIcon class="h-6 w-6 text-red-500" />
            )}
          </span>
        </div>

        {/* IF MAX BALANCE IS TRUE SHOW SPECS */}
        {specsToDeploy.maxBalanceProtection && (
          <div className="flex justify-between">
            <span>Max Balance:</span>
            <span className="font-thin">{specsToDeploy.maxBalance} %</span>
          </div>
        )}

        <div className="flex justify-between">
          <span>Max Txn Protection:</span>
          <span className="font-thin">
            {specsToDeploy.maxTxnProtection ? (
              <CheckIcon class="h-6 w-6 text-green-500" />
            ) : (
              <XMarkIcon class="h-6 w-6 text-red-500" />
            )}
          </span>
        </div>

        {/* IF MAX BALANCE IS TRUE SHOW SPECS */}
        {specsToDeploy.maxTxnProtection && (
          <div className="flex justify-between">
            <span>Max Txn:</span>
            <span className="font-thin">{specsToDeploy.maxTxn} %</span>
          </div>
        )}

        {!deploySuccess && (
          <Button
            onClick={deployClickHandler}
            buttonType={isBtnLoading ? "deployInactive" : "deploy"}
            disabled={isBtnLoading}
          >
            {!isConnected ? (
              <div>Connect wallet</div>
            ) : isBtnLoading ? (
              <div className="flex justify-center  items-center gap-2">
                <p>Processing</p>
                <div class="loader"></div>
              </div>
            ) : (
              <div>Open in wallet</div>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Erc20ReviewCard;
