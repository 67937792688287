import React, { useEffect, useState } from "react";
import Logo1 from "../../Assets/721a.svg";
import Logo2 from "../../Assets/erc1155.svg";
import Logo3 from "../../Assets/erc20.svg";
import Logo4 from "../../Assets/SBT.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CreateNewPageHead from "../../Components/CreateNewPageHead/CreateNewPageHead";
import Erc721AForm from "../../Components/Forms/Erc721AForm";
import Erc20Form from "../../Components/Forms/Erc20Form";
import Erc1155Form from "../../Components/Forms/Erc1155Form";
import SbtForm from "../../Components/Forms/SbtForm";
import DeploymentSteps from "../../Components/Steps/DeploymentSteps";
import PricingPlans from "../../Components/PricingPlans/PricingPlans";
import ReviewAndDeploy from "../../Components/ReviewAndDeploy/ReviewAndDeploy";

const CreateNew = () => {
  const [contractType, setContractType] = useState(1);
  const [activeStep, setActiveStep] = useState(1); //  change back to 1
  // const [planTier, setPlanTier] = useState(0);
  const [specsToDeploy, setSpecsToDeploy] = useState({});
  // const [wlRequired, setWlRequired] = useState(false);

  console.log("create new area specs", specsToDeploy);

  const location = useLocation();
  const currentPath = location.pathname;

  // console.log(planTier);

  return (
    <div className="w-full rounded-md bg-elements boxShadow1 px-7 py-6 space-y-14">
      <CreateNewPageHead
        logo={Logo3}
        description={`Customize and curate your ERC20 smart contract. Fill in the requirements below to build your wanted smart contract prior to proceeding to deployment`}
      />
      <DeploymentSteps activeStep={activeStep} setActiveStep={setActiveStep} />

      <div className="pt-14">
        {activeStep === 1 ? (
          <PricingPlans
            setActiveStep={setActiveStep}
            // setPlanTier={setPlanTier}
          />
        ) : activeStep === 2 ? (
          <Erc20Form
            setActiveStep={setActiveStep}
            // planTier={planTier}
            setSpecsToDeploy={setSpecsToDeploy}
          />
        ) : activeStep > 2 ? (
          <ReviewAndDeploy
            specsToDeploy={specsToDeploy}
            // planTier={planTier}
            setActiveStep={setActiveStep}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreateNew;
