import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
// import { useContract, useContractEvent } from "wagmi";
// import { DeployerContract } from "../../Utils/SmartContracts/DeployerContract/Constant";
// import { ethers } from "ethers";
import {
  fetchUserDeploys,
  fetchUserDeploysBlast,
} from "../../Utils/Helpers/ContractEvents";
import { useAccount, useNetwork } from "wagmi";
import HistoryCard from "./HistoryCard";
import ReactLoading from "react-loading";
import { ethers } from "ethers";
import { DeployerContractBlast } from "../../Utils/SmartContracts/DeployerContract/Constant";
import BlastHistoryCard from "./BlastHistoryCard";

const DeploymentHistory = () => {
  const [deployHistory, setDeployHistory] = useState([]);
  const [deployBlastHistory, setDeployBlastHistory] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isBlast, setIsBlast] = useState(false);
  const location = useLocation();

  const { chain } = useNetwork();

  const currentPath = location.pathname;

  const navigate = useNavigate();

  const createNewButtonHandler = () => {
    navigate("/create-new");
  };

  const { address, isConnected } = useAccount();

  // useContractEvent({
  //   address: DeployerContract.address,
  //   abi: DeployerContract.abi,
  //   eventName: "Erc721ADeployed",
  //   listener(node, resolver) {
  //     console.log(node, resolver);
  //   },
  //   // chainId: 5,
  // });

  // const contract = useContract({
  //   address: DeployerContract.address,
  //   abi: DeployerContract.abi,
  // });

  useEffect(() => {
    setLoaded(false);
    async function getPreviousLogs() {
      if (isConnected) {
        // if (chain?.id === 5) {
        if (chain?.id === 1) {
          const res = await fetchUserDeploys(address);
          setDeployHistory(res);
          setIsBlast(false);
          // console.log("hit");
        } else if (chain?.id === 168587773) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            DeployerContractBlast.address,
            DeployerContractBlast.abi,
            signer
          );
          setIsBlast(true);
          const getDeploys = await contract.getContractsByDeployer(address);
          // console.log(getDeploys);
          // const res = await fetchUserDeploysBlast(address);
          setDeployBlastHistory(getDeploys);
        }
      }

      setLoaded(true);
    }

    // if (contract) getPreviousLogs();
    getPreviousLogs();
  }, [address, isConnected, chain?.id]);

  const removeSlash = (inputString) => {
    return inputString.replace("/", "");
  };

  // console.log(deployBlastHistory);
  // console.log(isBlast);

  return (
    <div className="w-full min-h-[600px] rounded-md bg-elements boxShadow1 px-7  py-10 text-gray-400">
      <div className=" text-xl flex justify-between items-center border-b-[0.1px] border-gray-600 pb-6">
        <div>
          <p>Created</p>
          <p className="font-thin">
            A list of all {removeSlash(currentPath)} smart contracts previously
            deployed under your address.
          </p>
        </div>
        <Button onClick={createNewButtonHandler} buttonType="main">
          + Create new
        </Button>
      </div>
      {!loaded ? (
        <div className="flex  justify-center mt-44">
          <ReactLoading
            type={"spinningBubbles"}
            color="#66FF8E"
            height={70}
            width={80}
          />
        </div>
      ) : !isConnected ? (
        <div className="flex  justify-center mt-20 text-xl text-center text-accent">
          Connect wallet to view deployment history
        </div>
      ) : deployBlastHistory.length > 0 ? (
        <div className="font-thin px-6 py-8 space-y-4">
          <div className="">
            {deployBlastHistory.map((deployment) => (
              <BlastHistoryCard data={deployment} />
            ))}
          </div>
        </div>
      ) : deployHistory.length > 0 ? (
        <div className="font-thin px-6 py-8 space-y-4">
          {deployHistory.map((deployment) => (
            <HistoryCard
              id={deployment.args.contractAddress}
              data={deployment}
            />
          ))}
        </div>
      ) : (
        <div className="flex  justify-center mt-48 text-xl">
          No smart contracts found
        </div>
      )}
    </div>
  );
};

export default DeploymentHistory;
