import React, { useState } from "react";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { Button } from "../Button/Button";
import { NotifyError, NotifySuccess } from "../../Utils/Helpers/Notify";

const PhaseEnablerBtn = ({
  contractAddress,
  mintPhase,
  abi,
  setCurrentPhase,
}) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const ManagedContract = {
    address: contractAddress,
    abi: abi,
  };

  //   console.log(prepareError);

  // ============================ 📝 CONTRACT WRITES 📝 =============================
  const { config } = usePrepareContractWrite({
    ...ManagedContract,
    functionName: "setSaleState",
    args: [mintPhase],
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.reason);
    },
  });

  const {
    write: WriteFunction,
    data: functionWroteData,
    isSuccess: iswriteStarted,
  } = useContractWrite({
    ...config,
    onSuccess(data) {
      //   setCurrentProcessingStep(2);
    },
    onError(data) {
      setErrorMsg(data.message);
      NotifyError(data.message);
      setBtnLoading(false);
    },
  });

  const { isSuccess: txSuccess } = useWaitForTransaction({
    hash: functionWroteData?.hash,
    onSuccess(data) {
      NotifySuccess("mint phase updated");
      setBtnLoading(false);

      if (mintPhase === 0) {
        setCurrentPhase("PAUSED");
      } else if (mintPhase === 1) {
        setCurrentPhase("WHITELIST");
      } else if (mintPhase === 2) {
        setCurrentPhase("PUBLIC");
      }
    },
    onError(error) {
      NotifyError(error.message);
      setBtnLoading(false);
    },
  });

  const clickHandler = () => {
    if (isBtnLoading) return;

    if (prepareError) {
      NotifyError(errorMsg);
    } else {
      setBtnLoading(true);
      WriteFunction?.();
    }
  };

  return (
    <div className="w-32">
      <Button
        onClick={clickHandler}
        disabled={prepareError || isBtnLoading}
        buttonType={prepareError ? "deployInactive" : "deploy"}
      >
        Set phase
      </Button>
    </div>
  );
};

export default PhaseEnablerBtn;
