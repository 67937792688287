import React, { useState, useEffect } from "react";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { FormInput } from "../FormInput/FormInput";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { Button } from "../Button/Button";
import {
  blockInvalidChar,
  blockInvalidCharWithDecimal,
} from "../../Utils/Helpers/BlockInvalidChar";

const Erc20Form = ({ setActiveStep, setSpecsToDeploy }) => {
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [errors, setErrors] = useState({
    nameError: false,
    nameErrorMsg: "",
    tickerError: false,
    tickerErrorMsg: "",
    supplyError: false,
    supplyErrorMsg: "",
    buyTaxError: false,
    buyTaxErrorMsg: "",
    sellTaxError: false,
    sellTaxErrorMsg: "",
    maxBalanceErrorMsg: "",
    maxBalanceError: false,
    maxTxnError: false,
    maxTxnErrorMsg: "",
    ethPairError: false,
    ethPairErrorMsg: "",
    tokenPairError: false,
    tokenPairErrorMsg: "",
  });

  const [erc20Configs, setErc20Configs] = useState({
    name: "",
    symbol: "",
    supply: "",
    buyTax: "",
    sellTax: "",
    maxBalance: "",
    maxTxn: "",

    taxes: false,
    maxBalanceProtection: false,
    maxTxnProtection: false,
  });

  // string memory name,
  // string memory symbol,
  // uint256 tokenSupply,
  // uint8 maxWalletPercentage,
  // uint8 maxTxPercentage,
  // uint8 buyTax,
  // uint8 sellTax,
  // bool isTaxToken

  // Toggle switches
  const toggleTaxes = () => {
    setErc20Configs({
      ...erc20Configs,
      taxes: !erc20Configs.taxes,
    });
  };
  const toggleMaxBalanceProtection = () => {
    setErc20Configs({
      ...erc20Configs,
      maxBalanceProtection: !erc20Configs.maxBalanceProtection,
    });
  };
  const toggleMaxTxnProtection = () => {
    setErc20Configs({
      ...erc20Configs,
      maxTxnProtection: !erc20Configs.maxTxnProtection,
    });
  };

  // Form handlers
  const handleNameField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, name: newValue });

    if (newValue.length > 50) {
      setErrors({
        ...errors,
        nameError: true,
        nameErrorMsg: "Name should be less than 50 characters",
      });
    } else
      setErrors({
        ...errors,
        nameError: false,
        nameErrorMsg: "",
      });
  };

  const handleSymbolField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, symbol: newValue });

    if (newValue.length > 30) {
      setErrors({
        ...errors,
        tickerError: true,
        tickerErrorMsg: "Name should be less than 30 characters",
      });
    } else setErrors({ ...errors, tickerError: false, tickerErrorMsg: "" });
  };

  const handleSupplyField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, supply: newValue });

    if (newValue.length > 0 && newValue < 1) {
      setErrors({
        ...errors,
        supplyError: true,
        supplyErrorMsg: "Supply must be greater than 0",
      });
    } else setErrors({ ...errors, supplyError: false, supplyErrorMsg: "" });
  };

  const handleBuyTaxField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, buyTax: newValue });

    if (newValue.length > 0) {
      if (newValue > 100 || newValue < 1) {
        setErrors({
          ...errors,
          buyTaxError: true,
          buyTaxErrorMsg:
            "Supply must be lower than 100 and greater than 1% platform fee",
        });
      } else setErrors({ ...errors, buyTaxError: false, buyTaxErrorMsg: "" });
    } else setErrors({ ...errors, buyTaxError: false, buyTaxErrorMsg: "" });
  };

  const handleSellTaxField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, sellTax: newValue });

    if (newValue.length > 0) {
      if (newValue > 100 || newValue < 1) {
        setErrors({
          ...errors,
          sellTaxError: true,
          sellTaxErrorMsg:
            "Supply must be lower than 100 and greater than 1% platform fee",
        });
      } else setErrors({ ...errors, sellTaxError: false, sellTaxErrorMsg: "" });
    } else setErrors({ ...errors, sellTaxError: false, sellTaxErrorMsg: "" });
  };

  const handleMaxBalanceField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, maxBalance: newValue });

    if (newValue.length > 0) {
      if (newValue > 10 || newValue < 1) {
        setErrors({
          ...errors,
          maxBalanceError: true,
          maxBalanceErrorMsg: "Max balance limit must be between 1 and 10",
        });
      } else
        setErrors({
          ...errors,
          maxBalanceError: false,
          maxBalanceErrorMsg: "",
        });
    } else
      setErrors({ ...errors, maxBalanceError: false, maxBalanceErrorMsg: "" });
  };

  const handleMaxTxnField = (e) => {
    const newValue = e.target.value;
    setErc20Configs({ ...erc20Configs, maxTxn: newValue });

    if (newValue.length > 0) {
      if (newValue > 10 || newValue < 1) {
        setErrors({
          ...errors,
          maxTxnError: true,
          maxTxnErrorMsg: "Max transaction limit must be between 1 and 10",
        });
      } else setErrors({ ...errors, maxTxnError: false, maxTxnErrorMsg: "" });
    } else setErrors({ ...errors, maxTxnError: false, maxTxnErrorMsg: "" });
  };

  const goToReviewHandler = () => {
    setActiveStep(3);
    setSpecsToDeploy(erc20Configs);
  };

  useEffect(() => {
    if (
      !errors.nameError &&
      !errors.tickerError &&
      !errors.supplyError &&
      !errors.buyTaxError &&
      !errors.sellTaxError &&
      !errors.maxBalanceError &&
      !errors.maxTxnError &&
      !errors.ethPairError &&
      !errors.tokenPairError &&
      //
      erc20Configs.name.length > 0 &&
      erc20Configs.symbol.length > 0 &&
      erc20Configs.supply.length > 0 &&
      (erc20Configs.buyTax.length > 0 || !erc20Configs.taxes) &&
      (erc20Configs.sellTax.length > 0 || !erc20Configs.taxes) &&
      (erc20Configs.maxBalance.length > 0 ||
        !erc20Configs.maxBalanceProtection) &&
      (erc20Configs.maxTxn.length > 0 || !erc20Configs.maxTxnProtection)
    ) {
      setAllFieldsValid(true);
    } else setAllFieldsValid(false);
  }, [erc20Configs, errors]);

  return (
    <div className="mx-auto max-w-3xl space-y-8 text-gray-400">
      <ScrollToTop />
      {/* /////////////////////////  BASE TOKEN CONFIGS   ///////////////////////// */}
      <div className="flex flex-col  gap-1">
        <span className="text-accent text-3xl">Let's get you started</span>
        <span className=" font-thin text-lg">
          Set up the on-chain properties of your TOKEN and how it will appear
          when viewed in wallets.
        </span>
      </div>

      <FormInput
        title="Token name:"
        type="text"
        placeholder="Ex: Best Coin"
        inputType={errors.nameError ? "mainErrored" : "main"}
        error={errors.nameError}
        errorMsg={errors.nameErrorMsg}
        onChange={handleNameField}
        value={erc20Configs.name}
      />

      <div className="flex justify-between gap-10">
        <FormInput
          title="Ticker symbol:"
          type="text"
          placeholder="Ex: BESTC or $BESTC"
          inputType={errors.tickerError ? "mainErrored" : "main"}
          error={errors.tickerError}
          errorMsg={errors.tickerErrorMsg}
          onChange={handleSymbolField}
          value={erc20Configs.symbol}
        />
        <FormInput
          title="Total supply:"
          type="number"
          placeholder="Ex: 1, 000, 000"
          icon="TOKENS"
          inputType={errors.supplyError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidChar}
          onWheel={(e) => e.target.blur()}
          error={errors.supplyError}
          errorMsg={errors.supplyErrorMsg}
          onChange={handleSupplyField}
          value={erc20Configs.supply}
        />
      </div>

      {/* /////////////////////////  TOKEN TAX CONFIGS  ///////////////////////// */}
      <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">
          Set token tax configurations
        </span>
        <span className="font-thin text-lg">
          Set up your desired initial trading tax rates.
        </span>
      </div>

      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable token trading tax</p>
        <button onClick={toggleTaxes}>
          <ToggleSwitch status={erc20Configs.taxes} />
        </button>
      </div>

      {erc20Configs.taxes && (
        <div className="flex justify-between gap-10">
          <FormInput
            title="Initial buy tax:"
            type="number"
            placeholder="Ex: 10"
            icon="%"
            inputType={errors.buyTaxError ? "mainErrored" : "main"}
            onKeyDown={blockInvalidCharWithDecimal}
            onWheel={(e) => e.target.blur()}
            error={errors.buyTaxError}
            errorMsg={errors.buyTaxErrorMsg}
            onChange={handleBuyTaxField}
            value={erc20Configs.buyTax}
          />
          <FormInput
            title="Initial sell tax:"
            type="number"
            placeholder="Ex: 20"
            icon="%"
            inputType={errors.sellTaxError ? "mainErrored" : "main"}
            onKeyDown={blockInvalidChar}
            onWheel={(e) => e.target.blur()}
            error={errors.sellTaxError}
            errorMsg={errors.sellTaxErrorMsg}
            onChange={handleSellTaxField}
            value={erc20Configs.sellTax}
          />
        </div>
      )}

      {/* /////////////////////////  TOKEN LAUNCH SECURITY CONFIGS  ///////////////////////// */}
      <div className="flex flex-col  gap-1 pt-10">
        <span className="text-accent text-3xl">
          Set anti-bot configurations
        </span>
        <span className="font-thin text-lg">
          Set up your desired anti-bot protection measures.
        </span>
      </div>

      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable initial maximum wallet balance</p>
        <button onClick={toggleMaxBalanceProtection}>
          <ToggleSwitch status={erc20Configs.maxBalanceProtection} />
        </button>
      </div>

      {erc20Configs.maxBalanceProtection && (
        <FormInput
          title="Initial maximum balance limit:"
          type="number"
          placeholder="Ex: 2"
          icon="%"
          inputType={errors.maxBalanceError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidCharWithDecimal}
          onWheel={(e) => e.target.blur()}
          error={errors.maxBalanceError}
          errorMsg={errors.maxBalanceErrorMsg}
          onChange={handleMaxBalanceField}
          value={erc20Configs.maxBalance}
        />
      )}

      <div className=" text-2xl  flex justify-between items-center">
        <p>Enable initial maximum transaction amount</p>
        <button onClick={toggleMaxTxnProtection}>
          <ToggleSwitch status={erc20Configs.maxTxnProtection} />
        </button>
      </div>

      {erc20Configs.maxTxnProtection && (
        <FormInput
          title="Initial maximum transaction limit:"
          type="number"
          placeholder="Ex: 1"
          icon="%"
          inputType={errors.maxTxnError ? "mainErrored" : "main"}
          onKeyDown={blockInvalidCharWithDecimal}
          onWheel={(e) => e.target.blur()}
          error={errors.maxTxnError}
          errorMsg={errors.maxTxnErrorMsg}
          onChange={handleMaxTxnField}
          value={erc20Configs.whitelistMintPrice}
        />
      )}

      {/* /////////////////////////  NEXT STEP BTN  ///////////////////////// */}
      <div className="flex justify-between pt-10">
        <Button onClick={() => setActiveStep(1)} buttonType="mainSm">
          Back
        </Button>
        <Button
          disabled={!allFieldsValid}
          onClick={goToReviewHandler}
          buttonType={allFieldsValid ? "mainSm" : "mainSmInactive"}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Erc20Form;
