import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  Chain,
} from "@rainbow-me/rainbowkit";
import merge from "lodash.merge";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";

// const blast = {
//   id: 168587773,
//   name: "Blast Sepolia",
//   network: "blast",
//   nativeCurrency: {
//     decimals: 18,
//     name: "Ethereum",
//     symbol: "ETH",
//   },
//   rpcUrls: {
//     public: { http: ["https://sepolia.blast.io"] },
//     default: { http: ["https://sepolia.blast.io"] },
//   },
//   blockExplorers: {
//     etherscan: { name: "BlastScan", url: "https://testnet.blastscan.io" },
//     default: { name: "BlastScan", url: "https://testnet.blastscan.io" },
//   },
//   //   contracts: {
//   //     multicall3: {
//   //       address: '0xa334eBbf8552913a31F53FBA625592207D820080',
//   //       blockCreated: 1_044_927,
//   //     },
//   //   },
// };

// BLAST MAINNET
const blast = {
  id: 81457,
  name: "Blast",
  network: "blast",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum",
    symbol: "ETH",
  },
  rpcUrls: {
    public: { http: ["https://rpc.blast.io"] },
    default: { http: ["https://rpc.blast.io"] },
  },
  blockExplorers: {
    etherscan: { name: "BlastScan", url: "https://blastscan.io" },
    default: { name: "BlastScan", url: "https://blastscan.io" },
  },
  //   contracts: {
  //     multicall3: {
  //       address: '0xca11bde05977b3631167028862be2a173976ca11',
  //       blockCreated: 11_907_934,
  //     },
  //   },
};

const { chains, provider } = configureChains(
  // [goerli, blast],
  [mainnet, blast],
  [
    infuraProvider({
      apiKey: "b6bf7d3508c941499b10025c0776eaf8",
      priority: 0,
    }),
    publicProvider({ priority: 1 }),
  ]
);
const { connectors } = getDefaultWallets({
  appName: "Pulse",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const myTheme = merge(darkTheme(), {
  blurs: {
    modalOverlay: "small",
  },
  colors: {
    accentColor: "#66FF8E",
    // actionButtonSecondaryBackground: "#241440",
    // closeButtonBackground: "#241440",
    // connectButtonBackground: "#241440",
    // connectButtonBackgroundError: "...",
    // connectButtonInnerBackground: "wtv",
    // downloadBottomCardBackground: "...",
    // downloadTopCardBackground: "...",
    // menuItemBackground: "...",
    // modalBackground: "#241440",
  },
  fonts: {
    body: "DM sans",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider
            modalSize="compact"
            chains={chains}
            theme={myTheme}
          >
            <App />
          </RainbowKitProvider>
        </WagmiConfig>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
