import React from "react";

const BUTTON_TYPE_CLASSES = {
  main: "bg-accent px-3 py-1.5 rounded-sm boxShadow1 text-primary",
  mainSm:
    "bg-accent px-6 py-1.5 rounded-sm boxShadow1 text-primary font-semibold",
  deploy:
    "bg-accent px-3 py-1.5 rounded-sm boxShadow1 text-primary w-full hover:opacity-80 transition-all duration-300 cursor-pointer font-semibold",
  deployInactive:
    "bg-accent px-3 py-1.5 rounded-sm boxShadow1 text-primary w-full  transition-all duration-300 cursor-pointer opacity-70 cursor-default font-semibold",
  mainSmInactive:
    "bg-accent px-6 py-1.5 rounded-sm boxShadow1 text-primary opacity-50 cursor-default font-semibold",
  connectWallet:
    "w-40 h-10 rounded-sm flex justify-center items-center border-[0.5px] border-accent text-white cursor-pointer hover:bg-accent hover:bg-opacity-20 shadow-[0_0_5px_rgba(102,255,142,1)] hover:shadow-[0_0_10px_rgba(102,255,142,1)] transition-all duration-300 ",
  customConnectWallet:
    "px-6 h-10 rounded-sm flex justify-center items-center border-[0.5px] border-accent text-white cursor-pointer hover:bg-accent hover:bg-opacity-20 shadow-[0_0_5px_rgba(102,255,142,1)] hover:shadow-[0_0_10px_rgba(102,255,142,1)] transition-all duration-300 ",
  pricingSolid:
    "bg-accent text-primary hover:bg-opacity-80 transition-all duration-300 ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent w-full",
  pricingOutlined:
    "transition-all duration-300 text-accent ring-1 ring-inset ring-accent hover:ring-accent mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent hover:bg-opacity-20  hover:shadow-[0_0_10px_rgba(102,255,142,1)] w-full",
  manage:
    "transition-all duration-300 text-accent ring-1 ring-inset ring-accent hover:ring-accent block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent hover:bg-opacity-20  hover:shadow-[0_0_10px_rgba(102,255,142,1)] w-full",
};

export const Button = ({ children, buttonType, ...props }) => {
  return (
    <button className={BUTTON_TYPE_CLASSES[buttonType]} {...props}>
      {children}
    </button>
  );
};
