import React, { useEffect, useState } from "react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import ManageOverview from "../ManageOverview/ManageOverview";
import RevealEnablerBtn from "../ManageContractButtons/RevealEnablerBtn";
import { FormInput } from "../FormInput/FormInput";
import { useContractReads, useAccount } from "wagmi";
import { Erc20ABI } from "../../Utils/SmartContracts/TemplateContracts/Constant";
import ReactLoading from "react-loading";
import EnableTrading from "../Erc20ContractButtons/EnableTrading";
import DisableLimits from "../Erc20ContractButtons/DisableLimits";
import Renounce from "../Erc20ContractButtons/Renounce";
import { blockInvalidChar } from "../../Utils/Helpers/BlockInvalidChar";
import SetTaxes from "../Erc20ContractButtons/SetTaxes";
import SetLimits from "../Erc20ContractButtons/SetLimits";
import { ethers } from "ethers";
import SetTaxReceiver from "../Erc20ContractButtons/SetTaxReceiver";
import ExcludeWallet from "../Erc20ContractButtons/ExcludeWallet";

const Erc20Manager = ({ contractAddress }) => {
  const [loaded, setLoaded] = useState(false);
  const [specs, setSpecs] = useState([]);
  const [tradingActive, setTradingActive] = useState(false);
  const [limitsActive, setLimitsActive] = useState(false);

  const { isConnected, address } = useAccount();

  useEffect(() => {
    setLoaded(false);
  }, [contractAddress, isConnected, address]);

  const [errors, setErrors] = useState({
    buyTaxError: false,
    buyTaxErrorMsg: "",
    sellTaxError: false,
    sellTaxErrorMsg: "",
    maxBalanceErrorMsg: "",
    maxBalanceError: false,
    maxTxnError: false,
    maxTxnErrorMsg: "",
    newTaxReceiverError: false,
    newTaxReceiverErrorMsg: "",
    walletToExcludeError: false,
    walletToExcludeErrorMsg: "",
  });

  const [newConfigs, setNewConfigs] = useState({
    buyTax: "",
    sellTax: "",
    maxBalance: "",
    maxTxn: "",
    newTaxReceiver: "",
    walletToExclude: "",
  });
  // string name;
  // string symbol;
  // uint256 supply;
  // uint8 maxWallet;
  // uint8 maxTransaction;
  // uint8 buyTax;
  // uint8 sellTax;
  // bool isTaxToken;

  //   const { isConnected, address } = useAccount();

  //   const { data, isError, isLoading } = useContractRead({
  //     address: contractAddress,
  //     abi: Erc20ABI,
  //     functionName: "specifications",
  //     onSuccess(data) {
  //       console.log(data);
  //       setSpecs(data);
  //       setLoaded(true);
  //     },
  //   });

  const ManagedContract = {
    address: contractAddress,
    abi: Erc20ABI,
  };

  // console.log(ManagedContract.address);

  const { data, refetch } = useContractReads({
    contracts: [
      {
        ...ManagedContract,
        functionName: "tradingActive",
      },
      {
        ...ManagedContract,
        functionName: "limitsActive",
      },
      {
        ...ManagedContract,
        functionName: "specifications",
      },
    ],
    onSuccess(data) {
      setLoaded(false);
      // console.log(data[0]);
      setTradingActive(data?.[0]);
      setLimitsActive(data?.[1]);
      setSpecs(data?.[2]);
      setLoaded(true);
    },
  });

  //   useEffect(() => {
  //     setLoaded(false);
  //   }, [address, isConnected]);

  const handleBuyTaxField = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, buyTax: newValue });

    if (newValue.length > 0) {
      if (newValue > 100 || newValue < 1) {
        setErrors({
          ...errors,
          buyTaxError: true,
          buyTaxErrorMsg:
            "Supply must be lower than 100 and greater than 1% platform fee",
        });
      } else setErrors({ ...errors, buyTaxError: false, buyTaxErrorMsg: "" });
    } else setErrors({ ...errors, buyTaxError: false, buyTaxErrorMsg: "" });
  };

  const handleSellTaxField = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, sellTax: newValue });

    if (newValue.length > 0) {
      if (newValue > 100 || newValue < 1) {
        setErrors({
          ...errors,
          sellTaxError: true,
          sellTaxErrorMsg:
            "Supply must be lower than 100 and greater than 1% platform fee",
        });
      } else setErrors({ ...errors, sellTaxError: false, sellTaxErrorMsg: "" });
    } else setErrors({ ...errors, sellTaxError: false, sellTaxErrorMsg: "" });
  };

  const handleMaxBalanceField = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, maxBalance: newValue });

    if (newValue.length > 0) {
      if (newValue > 10 || newValue < 1) {
        setErrors({
          ...errors,
          maxBalanceError: true,
          maxBalanceErrorMsg: "Max balance limit must be between 1 and 10",
        });
      } else
        setErrors({
          ...errors,
          maxBalanceError: false,
          maxBalanceErrorMsg: "",
        });
    } else
      setErrors({ ...errors, maxBalanceError: false, maxBalanceErrorMsg: "" });
  };

  const handleMaxTxnField = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, maxTxn: newValue });

    if (newValue.length > 0) {
      if (newValue > 10 || newValue < 1) {
        setErrors({
          ...errors,
          maxTxnError: true,
          maxTxnErrorMsg: "Max transaction limit must be between 1 and 10",
        });
      } else setErrors({ ...errors, maxTxnError: false, maxTxnErrorMsg: "" });
    } else setErrors({ ...errors, maxTxnError: false, maxTxnErrorMsg: "" });
  };

  const handleNewTaxReceiver = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, newTaxReceiver: newValue });

    if (newValue.length > 0) {
      if (!ethers.utils.isAddress(newValue)) {
        setErrors({
          ...errors,
          newTaxReceiverError: true,
          newTaxReceiverErrorMsg: "Invalid wallet address",
        });
      } else
        setErrors({
          ...errors,
          newTaxReceiverError: false,
          newTaxReceiverErrorMsg: "",
        });
    } else
      setErrors({
        ...errors,
        newTaxReceiverError: false,
        newTaxReceiverErrorMsg: "",
      });
  };

  const handleWalletToExclude = (e) => {
    const newValue = e.target.value;
    setNewConfigs({ ...newConfigs, walletToExclude: newValue });

    if (newValue.length > 0) {
      if (!ethers.utils.isAddress(newValue)) {
        setErrors({
          ...errors,
          walletToExcludeError: true,
          walletToExcludeErrorMsg: "Invalid wallet address",
        });
      } else
        setErrors({
          ...errors,
          walletToExcludeError: false,
          walletToExcludeErrorMsg: "",
        });
    } else
      setErrors({
        ...errors,
        walletToExcludeError: false,
        walletToExcludeErrorMsg: "",
      });
  };

  return (
    <div>
      {loaded && isConnected && specs ? (
        <div>
          <ManageOverview
            tradingActive={tradingActive}
            limitsActive={limitsActive}
            specs={specs}
          />

          <div className="mx-auto max-w-3xl ">
            <div className="space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">ENABLE TRADING</span>
                <span className="font-thin text-lg">
                  Enable trading to allow users to buy and sell your token.
                  (THIS ACTION IS IRREVERSIBLE)
                </span>
              </div>

              <div className="flex justify-between  items-center   gap-8">
                <p className="text-2xl">Set trading live</p>

                <EnableTrading
                  contractAddress={contractAddress}
                  setTradingActive={setTradingActive}
                />
              </div>
            </div>

            <div className="space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">DISABLE LIMITS</span>
                <span className="font-thin text-lg">
                  Disable the maximum wallet and transaction limits to allow
                  freedom of transaction. (THIS ACTION IS IRREVERSIBLE)
                </span>
              </div>

              <div className="flex justify-between  items-center   gap-8">
                <p className="text-2xl">Remove limits</p>

                <DisableLimits
                  setLimitsActive={setLimitsActive}
                  contractAddress={contractAddress}
                />
              </div>
            </div>

            <div className="space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">RENOUNCE OWNERSHIP</span>
                <span className="font-thin text-lg">
                  Renounce ownership to make the contract fully decentralized.
                  (THIS ACTION IS IRREVERSIBLE)
                </span>
              </div>

              <div className="flex justify-between  items-center   gap-8">
                <p className="text-2xl">Forfeit ownership</p>

                <Renounce contractAddress={contractAddress} />
              </div>
            </div>

            {specs?.isTaxToken && (
              <div className="mx-auto max-w-3xl space-y-8 text-gray-400">
                <div className="flex flex-col  gap-1 mt-20">
                  <span className="text-accent text-3xl">SET NEW TAXES</span>
                  <span className="font-thin text-lg">
                    Set new buy and sell tax rates for your token.
                  </span>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="flex justify-between gap-10">
                    <FormInput
                      title="Buy tax:"
                      type="number"
                      placeholder="Ex: 20"
                      icon="%"
                      inputType={errors.buyTaxError ? "mainErrored" : "main"}
                      onKeyDown={blockInvalidChar}
                      onWheel={(e) => e.target.blur()}
                      error={errors.buyTaxError}
                      errorMsg={errors.buyTaxErrorMsg}
                      onChange={handleBuyTaxField}
                      value={newConfigs.buyTax}
                    />
                    <FormInput
                      title="Sell tax:"
                      type="number"
                      placeholder="Ex: 10"
                      icon="%"
                      inputType={errors.sellTaxError ? "mainErrored" : "main"}
                      onKeyDown={blockInvalidChar}
                      onWheel={(e) => e.target.blur()}
                      error={errors.sellTaxError}
                      errorMsg={errors.sellTaxErrorMsg}
                      onChange={handleSellTaxField}
                      value={newConfigs.sellTax}
                    />
                  </div>

                  <div className="pb-1 flex justify-end w-full">
                    <SetTaxes
                      contractAddress={contractAddress}
                      buyTax={newConfigs.buyTax}
                      sellTax={newConfigs.sellTax}
                      setSpecs={setSpecs}
                      specs={specs}
                      setNewConfigs={setNewConfigs}
                      newConfigs={newConfigs}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="mx-auto max-w-3xl space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">SET NEW LIMITS</span>
                <span className="font-thin text-lg">
                  Set new limits for max transaction amount and max wallet
                  balance amount. (THIS ACTION CAN ONLY BE CALLED WHILE LIMITS
                  ARE ACTIVE)
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex justify-between gap-10">
                  <FormInput
                    title="Max balance:"
                    type="number"
                    placeholder="Ex: 2"
                    icon="%"
                    inputType={errors.maxBalanceError ? "mainErrored" : "main"}
                    onKeyDown={blockInvalidChar}
                    onWheel={(e) => e.target.blur()}
                    error={errors.maxBalanceError}
                    errorMsg={errors.maxBalanceErrorMsg}
                    onChange={handleMaxBalanceField}
                    value={newConfigs.maxBalance}
                  />
                  <FormInput
                    title="Max txn:"
                    type="number"
                    placeholder="Ex: 1"
                    icon="%"
                    inputType={errors.maxTxnError ? "mainErrored" : "main"}
                    onKeyDown={blockInvalidChar}
                    onWheel={(e) => e.target.blur()}
                    error={errors.maxTxnError}
                    errorMsg={errors.maxTxnErrorMsg}
                    onChange={handleMaxTxnField}
                    value={newConfigs.maxTxn}
                  />
                </div>

                <div className="pb-1 flex justify-end w-full">
                  <SetLimits
                    contractAddress={contractAddress}
                    maxBal={newConfigs.maxBalance}
                    maxTxn={newConfigs.maxTxn}
                    setSpecs={setSpecs}
                    specs={specs}
                    setNewConfigs={setNewConfigs}
                    newConfigs={newConfigs}
                  />
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-3xl space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">
                  SET NEW TAX RECIPIENT
                </span>
                <span className="font-thin text-lg">
                  Set new tax receiver wallet address to receive payouts. (THIS
                  ACTION CAN ONLY BE CALLED WHILE CONTRACT IS NOT RENOUNCED)
                </span>
              </div>

              <div className="flex     gap-8">
                <FormInput
                  title="New wallet address:"
                  type="text"
                  placeholder="Ex: 0xb8f226ddb7bc672e27dffb67e4adabfa8c0dfa08"
                  inputType={
                    errors.newTaxReceiverError ? "mainErrored" : "main"
                  }
                  error={errors.newTaxReceiverError}
                  errorMsg={errors.newTaxReceiverErrorMsg}
                  onChange={handleNewTaxReceiver}
                  value={newConfigs.newTaxReceiver}
                />

                <div className="pt-[38px]">
                  <SetTaxReceiver
                    contractAddress={contractAddress}
                    taxReceiver={newConfigs.newTaxReceiver}
                    setNewConfigs={setNewConfigs}
                    newConfigs={newConfigs}
                  />
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-3xl space-y-8 text-gray-400">
              <div className="flex flex-col  gap-1 mt-20">
                <span className="text-accent text-3xl">EXCLUDE WALLET</span>
                <span className="font-thin text-lg">
                  Exclude wallet from tax fees and restrictions.
                </span>
              </div>

              <div className="flex    gap-8">
                <FormInput
                  title="Wallet address:"
                  type="text"
                  placeholder="Ex: 0xb8f226ddb7bc672e27dffb67e4adabfa8c0dfa08"
                  inputType={
                    errors.walletToExcludeError ? "mainErrored" : "main"
                  }
                  error={errors.walletToExcludeError}
                  errorMsg={errors.walletToExcludeErrorMsg}
                  onChange={handleWalletToExclude}
                  value={newConfigs.walletToExclude}
                />

                <div className="pt-[38px]">
                  <ExcludeWallet
                    contractAddress={contractAddress}
                    walletToExclude={newConfigs.walletToExclude}
                    setNewConfigs={setNewConfigs}
                    newConfigs={newConfigs}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !isConnected ? (
        <div className="flex  justify-center my-44 text-xl text-center text-accent">
          Connect wallet to view smart contract manager
        </div>
      ) : (
        <div className="flex justify-center my-44">
          <ReactLoading
            type={"spinningBubbles"}
            color="#66FF8E"
            height={70}
            width={80}
          />
        </div>
      )}
    </div>
  );
};

export default Erc20Manager;
