export const addCommasToIntegerString = (integerStr) => {
  if (!integerStr.match(/^\d+$/)) {
    return "Invalid input";
  }

  const parts = [];
  let part = "";
  let count = 0;

  for (let i = integerStr.length - 1; i >= 0; i--) {
    part = integerStr[i] + part;
    count++;

    if (count === 3 || i === 0) {
      parts.unshift(part);
      part = "";
      count = 0;
    }
  }

  return parts.join(",");
};
