import React from "react";
import { CustomConnect } from "../CustomConnect/CustomConnect";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../Utils/Helpers/ShortenAddress";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import Logo from "../../Assets/logo.png";

const Header = () => {
  const { address, isConnected } = useAccount();
  const { openAccountModal } = useAccountModal();

  const navigate = useNavigate();

  const goToHomeHandler = () => {
    navigate("/");
  };
  return (
    <div className="bg-secondary w-full h-[80px] fixed z-50 border-b-[0.5px] border-[#474747] flex justify-between items-center pl-2 pr-10">
      <div onClick={goToHomeHandler} className=" text-white ">
        <img src={Logo} alt="pulse ai" className="w-[150px] ml-4" />
      </div>

      <CustomConnect />
    </div>
  );
};

export default Header;
