import React from "react";

const INPUT_TYPE_CLASSES = {
  main: "h-[40px] w-full px-3 text-gray-400  bg-elements rounded-md  outline-none border border-gray-700 focus:border-white placeholder-gray-500 placeholder-opacity-50 ",
  mainErrored:
    "h-[40px] w-full px-3 text-gray-400  bg-elements rounded-md  outline-none border border-red-800 focus:border-white placeholder-gray-500 placeholder-opacity-50 ",
};

export const FormInput = ({
  error,
  errorMsg,
  title,
  inputType,
  icon,
  ...props
}) => {
  return (
    <div className="space-y-2 w-full">
      <span className=" text-lg text-gray-400 font-normal">{title}</span>
      <div className="relative pb-1">
        <input
          {...props}
          spellCheck={false}
          className={INPUT_TYPE_CLASSES[inputType]}
        />
        {icon && (
          <div className="absolute right-2  top-1/2 -translate-y-1/2 text-lg text-gray-400 font-normal">
            {icon}
          </div>
        )}
      </div>
      {error && <span className="text-red-800 ml-1">{errorMsg}</span>}
    </div>
  );
};
