import abi from "./DeployerAbi.json";
import blastAbi from "./DeployerBlastAbi.json";

// =========================== ABI =============================== //
const DeployerAbi = abi;

const DeployerBlastAbi = blastAbi;

// ====================== CONTRACT ADDRESS ======================= //

const DeployerAddress = "0x8467Ec2887bF5BFADbA7Fe178095F2bc00F6A5Cb";

// test eth 0xE512e250297af2B2eEa02f399AF2913B487228B6

const DeployerAddressBlast = "0x244BEed4eC7068EFf3523a54e638a2316CC01a94";

// 0xdef2C5a5aa7Db219693CFB8eec81495625B88494

// latest with script good for verification: 0xdc24a9400acf00CFcCAECDfa2A6Ac890c10f986A
// latest blast thru remix 0x03461E9a3A2Df8a06d77Cd57Aec721F00c4Ba1C7

//latest blast 0xab01aC001932343C997a6451b591a0A92fb32d86

// latest testnet with same erc20 file = 0xa6fed9653cc8738b53d461b6c86730fe531f7257
// newest testnet = 0x2316c221966196a6cc69eba9b5522865ebb69224
// testnet = 0xbfe18b6cfd90e29e69f94421694316d1cc5af832

// ========================== CONFIGS =========================== //
export const DeployerContract = {
  address: DeployerAddress,
  abi: DeployerAbi,
};

export const DeployerContractBlast = {
  address: DeployerAddressBlast,
  abi: DeployerBlastAbi,
};
